// @file Surface settings store
import { isAppUsing } from '@@/bits/flip'
import { getVuexStore } from '@@/bits/pinia'
import { useSurfaceStore } from '@@/pinia/surface'
import type { FreezeBannerState } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceFreezeStore = defineStore('surfaceFreeze', () => {
  const surfaceVuexStore = getVuexStore<RootState>()
  const surfaceStore = useSurfaceStore()

  const freezeBannerInformation = computed<FreezeBannerState | null>(
    () => surfaceVuexStore?.getters.freezeBannerInformation,
  )
  const freeTrial = computed((): boolean => freezeBannerInformation.value?.free_trial ?? false)
  const daysUntilFreeze = computed((): number | undefined => freezeBannerInformation.value?.days_until_freeze)
  const freezeBannerRole = computed((): string => freezeBannerInformation.value?.role ?? '')
  const freezeBannerPaymentLink = computed((): string | undefined => freezeBannerInformation.value?.payment_link)

  const freezeBannerHidden = ref(false)

  function closeFreezeBanner(): void {
    freezeBannerHidden.value = true
  }

  const isFrozenDueToFrozenTenant = computed(
    () =>
      isAppUsing('tenantFreeze') &&
      surfaceStore.isNativeTenant != null &&
      daysUntilFreeze.value !== undefined &&
      daysUntilFreeze.value <= 0,
  )

  return {
    // getters
    freezeBannerInformation,
    freeTrial,
    daysUntilFreeze,
    freezeBannerRole,
    freezeBannerPaymentLink,
    freezeBannerHidden,
    isFrozenDueToFrozenTenant,
    // actions
    closeFreezeBanner,
  }
})
