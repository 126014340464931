// @file Composable to track engagement time on a padlet.
import { trackWallEngagementTime } from '@@/bits/analytics'
import environment from '@@/bits/environment'
import { onMounted, onUnmounted } from 'vue'

const PING_INTERVAL_IN_SECONDS = 60

export function useWallEngagementTimePing(): void {
  let intervalId: any = null

  const setEngagementPingInterval = (): void => {
    if (intervalId != null) return
    intervalId = window.setInterval((): void => {
      trackWallEngagementTime()
    }, PING_INTERVAL_IN_SECONDS * 1000)
  }

  const clearEngagementPingInterval = (): void => {
    if (intervalId == null) return
    window.clearInterval(intervalId)
    intervalId = null
  }

  const onWindowVisibilityChange = (): void => {
    if (document.visibilityState === 'visible') {
      setEngagementPingInterval()
    } else {
      clearEngagementPingInterval()
    }
  }

  const onWindowFocus = (): void => {
    setEngagementPingInterval()
  }

  const onWindowBlur = (): void => {
    clearEngagementPingInterval()
  }

  onMounted((): void => {
    if (environment !== 'production') return
    window.addEventListener('visibilitychange', onWindowVisibilityChange)
    window.addEventListener('focus', onWindowFocus)
    window.addEventListener('blur', onWindowBlur)
    setEngagementPingInterval()
  })

  onUnmounted((): void => {
    if (environment !== 'production') return
    window.removeEventListener('visibilitychange', onWindowVisibilityChange)
    window.removeEventListener('focus', onWindowFocus)
    window.removeEventListener('blur', onWindowBlur)
    clearEngagementPingInterval()
  })
}
